import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@garmin-avcloud/avcloud-web-utils';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'fly-logout',
  template: ``,
  standalone: false
})
export class LogoutComponent implements OnInit {
  private readonly authService = inject(AuthService);

  ngOnInit(): void {
    this.authService.logOut(`${environment.flyLegacyUrl}/logout`);
  }
}
