import { Component, OnInit } from '@angular/core';
import { AuthService } from '@garmin-avcloud/avcloud-web-utils';

@Component({
  template: '',
  standalone: false
})
export class NotAuthorizedComponent implements OnInit {
  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.authService.redirectToSsoLoginPortal();
  }
}
