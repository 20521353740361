import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AVC_MAPS_DISPLAY_CONFIG, AvcMapsDisplayConfig } from '@garmin-avcloud/avc-maps-display';
import { AIRCRAFT_LOGBOOK_CONFIG, AircraftLogbookConfig } from '@garmin-avcloud/avcloud-aircraft-logbook';
import {
  AVDB_SUBSCRIPTIONS_SERVICE_TOKEN,
  GARMIN_PILOT_DEVICE_REGISTRATIONS_CONTROLLER_CLIENT_TOKEN,
  GARMIN_PILOT_DEVICE_REGISTRATIONS_SERVICE_TOKEN,
  MOBILE_DEVICE_SERVICE_TOKEN,
  PROMO_CODE_SERVICE_TOKEN,
  SKU_SERVICE_TOKEN,
  SUBSCRIPTIONS_CONTROLLER_CLIENT_TOKEN,
  SUBSCRIPTIONS_JOB_MANAGEMENT_TOKEN,
  SUBSCRIPTIONS_SERVICE_TOKEN
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { AircraftModule } from '@garmin-avcloud/avcloud-fly-web-common/api/aircraft';
import { AvionicsModule } from '@garmin-avcloud/avcloud-fly-web-common/api/avionics';
import { DevicesModule } from '@garmin-avcloud/avcloud-fly-web-common/api/devices';
import { OrgsRolesModule } from '@garmin-avcloud/avcloud-fly-web-common/api/orgs-roles';
import { PdiDomainV2Module } from '@garmin-avcloud/avcloud-fly-web-common/api/pdi/v2-0';
import { SubscriptionModule } from '@garmin-avcloud/avcloud-fly-web-common/api/subscription';
import { TelephonyDomainModule } from '@garmin-avcloud/avcloud-fly-web-common/api/telephony';
import { FLY_WEB_COMMON_CONFIG, FlyWebCommonConfig } from '@garmin-avcloud/avcloud-fly-web-common/config';
import {
  ORG_ACCESS_CONTROLS_TOKEN,
  ORG_SETTINGS_SERVICE_TOKEN,
  OrgContextInterceptorService
} from '@garmin-avcloud/avcloud-fly-web-common/orgs';
import { ComponentsGlobalWebModule, FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import {
  PROTOBUF_PARSER_SERVICE_CONFIG_TOKEN,
  ProtobufParserServiceConfig
} from '@garmin-avcloud/avcloud-web-data-services/aircraft-logbook';
import { WebDataServicesConfig, WEB_DATA_SERVICES_CONFIG } from '@garmin-avcloud/avcloud-web-data-services/config';
import {
  AIRCRAFT_SERVICE_CONFIG_TOKEN,
  AUTH_GUARD_CONFIG_TOKEN,
  AUTH_SERVICE_CONFIG_TOKEN,
  AircraftServiceConfig,
  AuthGuard,
  AuthGuardConfig,
  AuthServiceConfig,
  CUSTOMER_SERVICE_CONFIG_TOKEN,
  CustomerServiceConfig,
  HTTP_INTERCEPTOR_CONFIG_TOKEN,
  HttpInterceptorConfig,
  HttpInterceptorService
} from '@garmin-avcloud/avcloud-web-utils';
import {
  FEATURE_GUARD_CONFIG_TOKEN,
  FeaturesServiceConfig,
  featuresServiceConfigToken
} from '@garmin-avcloud/avcloud-web-utils/feature';
import { FlyGarminAppNavigationModule } from '@garmin-avcloud/flyGarminAppNavigation';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { environment } from '../../environments/environment';
import { OrgAccessControlsService } from '../features/orgs/org-access-controls/org-access-controls.service';
import { OrgSettingsService } from '../features/orgs/org-settings/org-settings.service';
import { AvdbSubscriptionsService } from '../shared/services/avdb-subscriptions.service';
import { CoreDataGpSubscriptionsClientService } from '../shared/services/coredata-gp-subscriptions-client.service';
import { CoreDataGpSubscriptionsService } from '../shared/services/coredata-gp-subscriptions.service';
import { GpDeviceRegistrationsClientService } from '../shared/services/gp-device-registrations-client.service';
import { GpDeviceRegistrationsService } from '../shared/services/gp-device-registrations.service';
import { MobileDeviceStatusService } from '../shared/services/mobile-device-status.service';
import { PromoCodeService } from '../shared/services/promo-code.service';
import { SkuService } from '../shared/services/sku.service';
import { SubscriptionJobManagementService } from '../shared/services/subscription-job-management.service';
import { SharedModule } from './../shared/shared.module';
import { EmptyLayoutComponent } from './components/layouts/empty-layout/empty-layout.component';
import { StandardLayoutComponent } from './components/layouts/standard-layout/standard-layout.component';
import { LogoutComponent } from './components/pages/logout/logout.component';

@NgModule({
  declarations: [EmptyLayoutComponent, LogoutComponent, StandardLayoutComponent],
  imports: [
    AircraftModule,
    AvionicsModule,
    DevicesModule,
    PdiDomainV2Module,
    OrgsRolesModule,
    CommonModule,
    RouterModule,
    SharedModule,
    ComponentsGlobalWebModule,
    FlyGarminAppNavigationModule,
    AircraftModule,
    DevicesModule,
    OrgsRolesModule,
    SubscriptionModule,
    TelephonyDomainModule
  ],
  providers: [
    AuthGuard,
    {
      provide: AIRCRAFT_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: environment.name === 'LOCAL' ? 'TEST' : environment.name,
        clientId: 'FLY_GARMIN_APP'
      } as AircraftServiceConfig
    },
    {
      provide: AIRCRAFT_LOGBOOK_CONFIG,
      useValue: {
        eligibleConnectedDevice: environment.connectedDevicesEligibleForAircraftLogbook
      } as AircraftLogbookConfig
    },
    {
      provide: AUTH_GUARD_CONFIG_TOKEN,
      useValue: {
        redirectRoute: '/not-authorized'
      } as AuthGuardConfig
    },
    {
      provide: AVC_MAPS_DISPLAY_CONFIG,
      useValue: {
        clientId: 'FLY_GARMIN_APP',
        environment: environment.name === 'LOCAL' ? 'TEST' : environment.name,
        mapAssetsPath: 'assets/map',
        capsUrl: `${environment.mapCapsUrl}`
      } as AvcMapsDisplayConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrgContextInterceptorService,
      multi: true
    },
    {
      provide: AUTH_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: environment.name === 'LOCAL' ? 'TEST' : environment.name,
        clientId: 'FLY_GARMIN_APP'
      } as AuthServiceConfig
    },
    {
      provide: CUSTOMER_SERVICE_CONFIG_TOKEN,
      useValue: {
        environment: environment.name === 'LOCAL' ? 'TEST' : environment.name,
        clientId: 'FLY_GARMIN_APP'
      } as CustomerServiceConfig
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: GARMIN_PILOT_DEVICE_REGISTRATIONS_CONTROLLER_CLIENT_TOKEN,
      useExisting: GpDeviceRegistrationsClientService
    },
    {
      provide: GARMIN_PILOT_DEVICE_REGISTRATIONS_SERVICE_TOKEN,
      useExisting: GpDeviceRegistrationsService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTOR_CONFIG_TOKEN,
      useValue: {
        ignoreList: ['https://static.garmincdn.com'],
        clientId: 'FLY_GARMIN_APP'
      } as HttpInterceptorConfig
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: FLY_WEB_COMMON_CONFIG,
      useValue: {
        environment: environment.name,
        regaliaIconsPath: 'assets/garmin-regalia-icons',
        site: 'FGA'
      } as FlyWebCommonConfig
    },
    {
      provide: featuresServiceConfigToken,
      useValue: {
        gatewayUrl: environment.flyLegacyUrl,
        serviceApiPath: '/api/v2/feature-flags',
        features: Object.values(FeatureFlyGarmin)
      } as FeaturesServiceConfig
    },
    {
      provide: FEATURE_GUARD_CONFIG_TOKEN,
      useValue: {
        redirectRoute: '/not-found'
      }
    },
    {
      provide: WEB_DATA_SERVICES_CONFIG,
      useValue: {
        environment: environment.name === 'LOCAL' ? 'TEST' : environment.name
      } as WebDataServicesConfig
    },
    {
      provide: PROTOBUF_PARSER_SERVICE_CONFIG_TOKEN,
      useValue: {
        protoBundlePath: 'assets/bundle.json',
        webWorkerPath: 'assets/protobuf-parser.worker.js'
      } as ProtobufParserServiceConfig
    },
    {
      provide: PROMO_CODE_SERVICE_TOKEN,
      useExisting: PromoCodeService
    },
    {
      provide: SUBSCRIPTIONS_CONTROLLER_CLIENT_TOKEN,
      useExisting: CoreDataGpSubscriptionsClientService
    },
    {
      provide: SUBSCRIPTIONS_JOB_MANAGEMENT_TOKEN,
      useExisting: SubscriptionJobManagementService
    },
    {
      provide: SUBSCRIPTIONS_SERVICE_TOKEN,
      useExisting: CoreDataGpSubscriptionsService
    },
    {
      provide: SKU_SERVICE_TOKEN,
      useExisting: SkuService
    },
    {
      provide: AVDB_SUBSCRIPTIONS_SERVICE_TOKEN,
      useExisting: AvdbSubscriptionsService
    },
    {
      provide: ORG_SETTINGS_SERVICE_TOKEN,
      useExisting: OrgSettingsService
    },
    {
      provide: ORG_ACCESS_CONTROLS_TOKEN,
      useExisting: OrgAccessControlsService
    },
    {
      provide: MOBILE_DEVICE_SERVICE_TOKEN,
      useExisting: MobileDeviceStatusService
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(trace: TraceService, @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule != null) {
      throw new Error('CoreModule has already been loaded. Import CoreModule in AppModule only.');
    }
  }
}
